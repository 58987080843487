import { useRouteLoaderData } from 'react-router'
import { type loader as rootLoader } from '~/root'
import type { EventCategory } from '~/types/eventCategory'

export function useCategories(flatten = false): EventCategory[] {
	const data = useRouteLoaderData<typeof rootLoader>('root')
	if (!data || !data.categories) {
		return []
	}
	if (flatten) {
		return flattenCategories(data.categories)
	}
	return data.categories
}

function flattenCategories(
	categories: EventCategory[],
	level: number = 0,
): EventCategory[] {
	const result: EventCategory[] = []
	function flatten(category: EventCategory, currentLevel: number) {
		const prefix = '-'.repeat(currentLevel)
		result.push({ ...category, name: `${prefix} ${category.name}` })
		category.children?.forEach(child => flatten(child, currentLevel + 1))
	}
	categories.forEach(category => flatten(category, level))
	return result
}
